import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import i18n from '@/locales/init';
import { IReadingRecordItem } from '@/api/history';
import { StatusLabel } from '../../components/StatusLabel';
import lessModule from './item.module.less';
export interface IReadingRecordItemProps {
  item: IReadingRecordItem;
  onHomePage?: boolean;
}

export const ReadingRecordItem = React.memo<IReadingRecordItemProps>(({
  item, onHomePage
}) => {
  const statusText = ['',i18n.t('common.dauyan_not_done'), i18n.t('common.dauyan_some_complete')][item.status];
  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} onClick={()=>{
    window.open(window.location.origin + `/reading/#/reading-comprehension/${item.paper_id}?record_id=${item.id}`);
  }}>
    <div className={lessModule.title}>{item.title}</div>
    <div className={lessModule.content}>{item.content}</div>
    {!onHomePage && <div className={lessModule.done}>{item.correct_num || item.total_num ? item.correct_num + '/' + item.total_num : '--'}</div>}
    <div className={lessModule.type}>
      <StatusLabel text={statusText} complete={item.status === 2}></StatusLabel>
    </div>
    <div className={lessModule.time}>{dayjs(item.create_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
    {
      !onHomePage && item.status === 2 && <div className={lessModule.redo}>
        <SimpleButton onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault();
          window.open(window.location.origin + `/reading/#/reading-comprehension/${item.paper_id}`);
        }}>{i18n.t('common.dauyan_more_tool_redo')}</SimpleButton>
      </div>
    }
  </div>;
});
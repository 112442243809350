import React from 'react';
import { ReactNode } from 'react';
import lessModule from './layout.module.less';

export interface ILayoutProps {
  title: string;
  header?: ReactNode;
  body: ReactNode;
}

export const Layout = React.memo<ILayoutProps>(({
  title, header, body
}) => {

  return <div className={lessModule.wrapper}>
    <div className={lessModule.header}>
      <div className={lessModule.title}>{title}</div>
      {
        header ? <div className={lessModule.headerChildren}>{header}</div> : null
      }
    </div>
    <div className={lessModule.body}>{body}</div>
  </div>;
});

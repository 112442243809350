import React, { FC } from 'react';
import classnames from 'classnames';

export interface IconFontParams extends React.HTMLAttributes<HTMLSpanElement> {
  type: string;
  style?: React.CSSProperties;
  fill?: string;
  /**图标尺寸 */
  size?: string | number;
}

export const SimpleIconFont: FC<IconFontParams> = (params) => {
  const { type, style, className, fill, size, ...rest } = params;
  const sizeStyle = size ? { fontSize: `${parseFloat(size as string)}px` } : {};
  return (
    <span
      style={{ ...style, ...sizeStyle }}
      {...rest}
      className={classnames(className, 'anticon', 'icon-dauyan')}
    >
      <svg width="1em" height="1em" fill={fill ? fill : 'currentColor'}>
        <use xlinkHref={`#${type}`}></use>
      </svg>
    </span>
  );
};

import React, { FC, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import { Input, InputProps } from 'antd';
import css from './style.module.less';

export interface SimpleInputProps extends InputProps {

}

export const SimpleInput = forwardRef<any, SimpleInputProps>((props, ref: any) => {
  useEffect(() => {
    if (props.autoFocus) {
      const el = document.querySelector(`#${props.id}`) as HTMLElement;
      if (el) {
        setTimeout(() => {
          el.autofocus = true;
          el.focus();
        }, 0);
      }
    }
  }, []);
  return <Input {...props} className={classNames(props.className, css.simpleInput)} ref={ref} />;
});
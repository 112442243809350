import { useEffect, useState } from 'react';

export const useDocumentVisible = (fn:()=> void)=>{
  const [visibility, setVisibility] = useState('');
  useEffect(()=>{
    let fc: ()=>void;
    document.addEventListener('visibilitychange', fc = ()=>{
      setVisibility(document.visibilityState);
    });
    return ()=>{
      fc && document.removeEventListener('visibilitychange', fc);
    };
  },[]);
  useEffect(()=>{
    if (visibility === 'visible'){
      fn();
    }
  },[visibility]);
};
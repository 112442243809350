import classNames from 'classnames';
import style from './index.module.less';
interface IStatusLabel {
  text?: string;
  complete?: boolean;
  status?: number;
}
export const StatusLabel = (props: IStatusLabel)=>{
  const {text, complete, status} = props;
  console.log(status);
  return <div className={
    classNames(
      style.lable, 
      complete ? style.complete : '', 
      status !== undefined && style[`status${status}`]
    )
  }>{text || ''}</div>;
};
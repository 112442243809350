import { Link } from 'react-router-dom';
import { Select } from 'antd';
import i18n, { getInitLang, LANG_MAP, setLanguage } from '@/locales/init';
import './index.less';

export const PageBottom = ()=>{
  return <footer className='layout-footer-box'>
    <a className='footer-item' target='_blank' href={'https://beian.miit.gov.cn/'}>{i18n.t('common.dauyan_website_recorde_code')}</a>
    <div className='footer-item'>{i18n.t('common.dauyan_shenzhendauyan')}</div>
    <div className='footer-item'>{i18n.t('common.dauyan_phone_number')}</div>
    <Select
      defaultValue={getInitLang()}
      style={{ width: 120 }}
      onChange={(data) => {
        setLanguage(data);
        window.location.reload();
      }}
      options={LANG_MAP}
      className='footer-item'
      suffixIcon={null}
      popupClassName='footer-item-menu'
    />
  </footer>;
};
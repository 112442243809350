/**
 * 一些常量信息
 */

// dauyan 内部业务名称区分
export const APP_NAME = 'IELTS';

export const PUBLIC_PATH = '/';

// 布局相关
// 适配最小屏幕宽度
export const MIN_WIDTH = 1200;
export const MIN_SIDE_WIDTH = 464;
export const MIN_SIDE_HEIGHT = 450;

// 配合媒体查询 1400 以下的视口宽度进行计算
export const MIN_SCREEN_SIDE_WIDTH_1400_2_CLIENT = 1400;
export const MIN_SCREEN_SIDE_WIDTH_1300_2_CLIENT = 1300;
export const MIN_SCREEN_SIDE_WIDTH_1200_2_CLIENT = 1200;
export const MIN_SCREEN_SIDE_WIDTH_1100_2_CLIENT = 1100;
export const MIN_SCREEN_SIDE_WIDTH_1000_2_CLIENT = 1000;
export const MIN_SCREEN_SIDE_WIDTH_900_2_CLIENT = 900;

export const MIN_SCREEN_SIDE_WIDTH_1400 = 360;
export const MIN_SCREEN_SIDE_WIDTH_1300 = 350;
export const MIN_SCREEN_SIDE_WIDTH_1200 = 340;
export const MIN_SCREEN_SIDE_WIDTH_1100 = 330;
export const MIN_SCREEN_SIDE_WIDTH_1000 = 330;
export const MIN_SCREEN_SIDE_WIDTH_900 = 320;

// 顶部导航
export const LAYOUT_HEIGHT = 72;

// 默认雅思目标分数
export const DEFAULT_SCORE = 7;

// 定时保存
export const AUTO_SAVE = 1000 * 10;


export const DIVIDER_MAX = 450;
export const DIVIDER_MIN = 200;


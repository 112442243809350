/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  MIN_SCREEN_SIDE_WIDTH_1400_2_CLIENT,
  MIN_SCREEN_SIDE_WIDTH_1300_2_CLIENT,
  MIN_SCREEN_SIDE_WIDTH_1200_2_CLIENT,
  MIN_SCREEN_SIDE_WIDTH_1100_2_CLIENT,
  MIN_SCREEN_SIDE_WIDTH_1000_2_CLIENT,
  MIN_SCREEN_SIDE_WIDTH_900_2_CLIENT,

  MIN_SCREEN_SIDE_WIDTH_1400,
  MIN_SCREEN_SIDE_WIDTH_1300,
  MIN_SCREEN_SIDE_WIDTH_1200,
  MIN_SCREEN_SIDE_WIDTH_1100,
  MIN_SCREEN_SIDE_WIDTH_1000,
  MIN_SCREEN_SIDE_WIDTH_900,

} from '@/utils/const';
import i18n from '@/locales/init';

// 使用正则表达式匹配中文字符
export function isChinese(string: string) {
  const pattern = /^[\u4e00-\u9fa5]+$/;
  return pattern.test(string);
}

export function isIncludeChinese(string: string) {
  const pattern = /[\u4e00-\u9fa5]/;
  return pattern.test(string);
}

// 匹配 26个英文字母
export function isEn(string: string) {
  const pattern = /^[a-zA-Z/]{1,}$/;
  return pattern.test(string);
}

export function getEn(str: string) {
  const englishOnly = str.match(/[a-zA-Z]+/g);
  return englishOnly ? englishOnly.join('') : '';
}

export function isEnglishWord(word: string) {
  const pattern = /^[a-zA-Z']+$/;
  return pattern.test(word);
}

// emoji
export const emoji = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/g;

export const reg = /\$([0-9]+(?:\.[0-9]+)?)\$/g;
export const reg_any = /\$.*?\$/g;
export const reg_any_gi = /[^\w\s]/gi;
export const reg_ai_help = /\$\.\.\.\$/g;
export const reg_ai_help_defalut = /\.\.\./g;

export const replaceStrWithRegGi = (str: string,) => {
  return str.replaceAll(reg_any_gi, '').trim();
};

export const replaceStr = (str: string) => {
  return str.replaceAll(reg_any, '_');
};

export const replaceInputStr = (str: string) => {
  return str.replace(i18n.t('common.dauyan_drag_to_here'), '').replace(reg_any, '');
};

// 产生随机 id
export const generateObjectId = (
  m = Math,
  d = Date,
  h = 16,
  s = (s: any) => m.floor(s).toString(h),
) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

export const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;


export function isStringOnlyWhitespace(str: string) {
  return /^\s*$/.test(str);
}

export const getScreenWidth = () => {
  const innerWidth = window.innerWidth;

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_900_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_900;
  }

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_1000_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_1000;
  }

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_1100_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_1100;
  }

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_1200_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_1200;
  }

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_1300_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_1300;
  }

  if (innerWidth <= MIN_SCREEN_SIDE_WIDTH_1400_2_CLIENT) {
    return MIN_SCREEN_SIDE_WIDTH_1400;
  }

  return MIN_SCREEN_SIDE_WIDTH_1400;
};

// @ts-ignore
export const throttle = (func: any, context: any, delay: any) => {
  let timeoutId: any;
  let lastExecTime = 0;
  return (...args: any) => {
    const currentTime = Date.now();
    if (currentTime - lastExecTime > delay) {
      func.apply(context, args);
      lastExecTime = currentTime;
    } else {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
        lastExecTime = Date.now();
      }, delay);
    }
  };
};

export function splitUpperCaseWords(str: string) {
  // 使用正则表达式匹配大写单词，并将它们拆分成数组
  const words = str.match(/[A-Z]+(?![a-z])|[A-Z][a-z]+/g);
  return words;
}

export function getAllIndices(str: string, subStr: string) {
  const indices = [];
  let index = str.indexOf(subStr);
  while (index !== -1) {
    indices.push(index);
    index = str.indexOf(subStr, index + 1);
  }
  return indices;
}

export function isPunctuation(str: string) {
  const punctuationRegex = /^[^\w\s]$/;
  return punctuationRegex.test(str);
}


export function insertString(originalString: string, insertString: string, position: number) {
  return originalString.substring(0, position) + insertString + originalString.substring(position);
}

export function extractEnglishParts(str: string) {
  const regex = /[a-zA-Z]+/g;
  const matches = str.match(regex);
  return matches?.join(' ');
}

/**
 * 使用路径名判断模块
 * @param path 
 * @returns 
 */
export function checkModule(path: string) {
  return window.location.pathname === path;
}

/**
 * 使用 hash 判断模块
 * @param hash 
 * @returns 
 */
export function checkHash(hash: string) {
  return window.location.hash.includes(hash);
}

/**
 * 创建拖拽按钮
 * @returns 
 */
export function createDragBtn() {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  svg.style.width = '1.22rem';
  svg.style.height = '1.22rem';
  svg.setAttribute('fill', 'currentColor');
  svg.setAttribute('viewBox', '0 0 24 24');
  path.setAttribute('d', 'M8.25 6.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Zm0 7.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Zm1.75 5.5a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0ZM14.753 6.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM16.5 12a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0Zm-1.747 9a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z');
  svg.appendChild(path);

  return svg;
}

export const logo_url = 'https://duy-ielts-public.oss-cn-hangzhou.aliyuncs.com/front/brand_core.png';

export const question_icon_1 = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_2906_3883)">
<path d="M10 0C4.47703 0 0 4.47696 0 10C0 15.523 4.47703 20 10 20C15.523 20 20 15.523 20 10C20 4.47696 15.523 0 10 0ZM10.1228 13.0517C9.689 13.4855 8.98553 13.4855 8.55165 13.0517L5.8928 10.3928C5.67586 10.1759 5.67586 9.82414 5.8928 9.6072C6.10974 9.39026 6.46146 9.39026 6.6784 9.6072L9.33732 12.2661L13.8215 7.78177C14.0385 7.56483 14.3902 7.56482 14.6071 7.78175C14.8241 7.99869 14.8241 8.35044 14.6072 8.5674L10.1228 13.0517Z" fill="#33AC79"/>
</g>
<defs>
<clipPath id="clip0_2906_3883">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;

export const question_icon_2 = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_2906_3845)">
<path d="M0.5 10C0.5 4.7531 4.75317 0.5 10 0.5C15.2468 0.5 19.5 4.7531 19.5 10C19.5 15.2468 15.2468 19.5 10 19.5C4.75317 19.5 0.5 15.2468 0.5 10ZM14.798 14.7981C15.2102 14.3859 15.2102 13.7175 14.798 13.3053L11.4927 10L14.798 6.69474C15.2102 6.28252 15.2102 5.61417 14.798 5.20197C14.3858 4.78979 13.7175 4.78981 13.3053 5.20201L10 8.50733L6.69466 5.20199C6.28247 4.7898 5.61416 4.7898 5.20196 5.202C4.78976 5.6142 4.78976 6.2825 5.20196 6.6947L8.50726 10L5.20196 13.3053C4.78976 13.7175 4.78976 14.3858 5.20196 14.798C5.61416 15.2102 6.28247 15.2102 6.69467 14.798L10 11.4927L13.3053 14.798C13.7175 15.2102 14.3858 15.2102 14.798 14.7981Z" fill="#EF4444" stroke="#EF4444"/>
</g>
<defs>
<clipPath id="clip0_2906_3845">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;

export function splitStringByArray(str: string, arr: any) {
  const result: any[] = [];
  let lastIndex = 0;

  for (let i = 0; i < arr.length; i++) {
    const { index, length } = arr[i];
    // 添加上一个分割点到当前分割点之间的字符串
    result.push(str.substring(lastIndex, index));
    // 添加当前分割的字符串
    result.push(str.substring(index, index + length));
    // 更新上一个分割点
    lastIndex = index + length;
  }
  // 添加最后一个分割点到字符串末尾的字符串
  if (lastIndex < str.length) {
    result.push(str.substring(lastIndex));
  }
  return result;
}

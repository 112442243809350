import { Modal } from 'antd';
import jsBridgeApi from '@/utils/jsBirdge';
import { payUrl } from '@/utils/env';
import { isNative } from '@/utils/platform';
import i18n from '@/locales/init';


let permissionModalVisible = false;

// TODO: 目前web和native支持的产品不一致，需要在必要的时候做处理

export const PermisssionModalNative = {
  open: () => {
    if (permissionModalVisible) {
      return;
    }
    permissionModalVisible = true;
    // 弹窗中断的情况下，清理选区
    if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
      (document.activeElement as (HTMLInputElement | HTMLTextAreaElement)).blur();
    }
    Modal.confirm({
      centered: true,
      style: { color: '#4A9E81' },
      title: i18n.t('common.dauyan_current_function_is_paid_feature'),
      okText: i18n.t('common.dauyan_go_to_payment'),
      cancelText: i18n.t('common.dauyan_i_know'),
      content: i18n.t('common.dauyan_activate_vip_member_enjoy_ai_services'),
      okButtonProps: {
        style: { outline: 'none', background: '#4A9E81' }
      },
      // cancelButtonProps: {
      //   style: { display: 'none', },
      // },
      onOk: () => {
        permissionModalVisible = false;
        jsBridgeApi.openPage('/buy_vip');
      },
      onCancel: () => {
        permissionModalVisible = false;
      },
    });
  },
};

export const PermissionExpiredModalWeb = {
  open: (cancelable = true) => {
    console.log(1111, cancelable, window.location.pathname);

    if (permissionModalVisible) {
      return;
    }
    permissionModalVisible = true;
    // 弹窗中断的情况下，清理选区
    if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
      (document.activeElement as (HTMLInputElement | HTMLTextAreaElement)).blur();
    }
    Modal.confirm({
      title: i18n.t('common.dauyan_writing_membership_expired'),
      content: '',
      okText: i18n.t('common.dauyan_writing_membership_recharge'),
      cancelText: cancelable ? i18n.t('common.dauyan_cancel') : null,
      okButtonProps: {
        style: { outline: 'none', background: '#4A9E81' }
      },
      cancelButtonProps: {
        style: { display: cancelable && window.location.pathname !== '/' ? 'inline' : 'none' }
      },
      onCancel: () => {
        console.log('cancel', document.getSelection());
        permissionModalVisible = false;
      },
      onOk() {
        permissionModalVisible = false;
        const encoded = btoa(window.location.href);
        window.location.href = `${payUrl}?redirectUrl=${encoded}`;
      },
      afterClose: () => {
        console.log('after close', document.getSelection());
      },
    });
  },
};

export const PermisssionModal = {
  open: (cancelable = true) => {
    if (isNative()) {
      return PermisssionModalNative.open();
    } else {
      return PermissionExpiredModalWeb.open(cancelable);
    }
  },
};

export const gotoPaymentPage = () => {
  if (isNative()) {
    jsBridgeApi.openPage('/buy_vip');
  } else {
    const encoded = btoa(window.location.href);
    window.location.href = `${payUrl}?redirectUrl=${encoded}`;
  }
};

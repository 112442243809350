import React from 'react';
import dayjs from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { logCount } from '@/utils/logger/arms-config';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import i18n from '@/locales/init';
import { IWritingTask2Evaluation } from '@/api/history';
import { useAppDispatch } from '@/stores';
import { saveEvaluationImages, saveWritingTask2EvaluationDraft } from '@/api/writing/evaluation';
import { StatusLabel } from '../../components/StatusLabel';
import lessModule from './item.module.less';

const getStatus = (state: number) => {
  if (state === 1) {
    return i18n.t('common.dauyan_in_progress');
  }
  if (state === 2) {
    return i18n.t('common.dauyan_grading_in_progress');
  }
  return i18n.t('common.dauyan_some_complete');
};

export interface IWritingTask2EvaluationItemProps {
  item: IWritingTask2Evaluation;
  onHomePage?: boolean;
}

export const WritingTask2EvaluationItem = React.memo<IWritingTask2EvaluationItemProps>(({
  item, onHomePage
}) => {
  const navigate = useNavigate();
  const handleClick = useMemoizedFn(() => {
    logCount('大作文批改历史记录进入详情');
    // navigate(`/writing-task-2/${item.id}`);
    window.location.href = `${window.location.origin}/#/writing-task-2/${item.id}`;
  });

  // if (!item.paper_id) {
  //   return null;
  // }

  const handleRedo = async ()=>{
    const res = await saveWritingTask2EvaluationDraft({ });
    if (res.data.record_id){
      await saveEvaluationImages({
        evaluation_id: res.data.record_id,
        file_url: [],
        type: 2
      });
      saveWritingTask2EvaluationDraft({ 
        paper_id: item.paper_id,
        record_id: res.data.record_id,
        content: item.content,
        subject: item.paper_content
      }).then((res)=>{
        // navigate(`/writing-task-2/${res.data.record_id}`);
        window.location.href = `${window.location.origin}/#/writing-task-2/${res.data.record_id}`;
      });
    }
  };

  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} onClick={handleClick}>
    <div className={lessModule.title}>{item.paper_title}</div>
    <div className={lessModule.content}>{item.paper_content}</div>
    <div className={lessModule.status}>
      <StatusLabel text={getStatus(item.status)} complete={item.status === 3}></StatusLabel>
    </div>
    {
      onHomePage ? <>
        <div className={lessModule.score}>{item.status === 3 ? item.score : ''}</div>
        {
          <div className={lessModule.redo}>
            <SimpleButton onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();
              // window.open(window.location.origin + `/writing/#/evaluation/${item.paper_id}`);
              // navigate('/big-essay/');
              handleRedo();
            }}>{i18n.t('common.dauyan_recorrect_task1')}</SimpleButton>
          </div>
        }
        {/* {
          item.status === 3 && <div className={lessModule.redo}>
            <SimpleButton onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();

            }}>{i18n.t('common.dauyan_download')}</SimpleButton>
          </div>
        } */}
      </>:<>
        <div className={lessModule.time}>{dayjs(item.update_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
        <div className={lessModule.score}>{item.status === 3 ? item.score : ''}</div>
        {
          item.status === 3 && <div className={lessModule.redo}>
            <SimpleButton onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();
              // window.open(window.location.origin + `/writing/#/evaluation/${item.paper_id}`);
              // navigate('/big-essay/');
              handleRedo();
            }}>{i18n.t('common.dauyan_recorrect_task1')}</SimpleButton>
          </div>
        }
      </>
    }
  </div>;
});

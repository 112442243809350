import { remove } from 'lodash-es';
import jsCookie from 'js-cookie';


const PLATFORM = 'platform';
const ANDROID = 'android';
const IOS = 'ios';
const MOUSE_DOWN = 'mousedown';
const MOUSE_UP = 'mouseup';
const MOUSE_MOVE = 'mousemove';
const TOUCH_START = 'touchstart';
const TOUCH_END = 'touchend';
const TOUCH_MOVE = 'touchmove';
const SELECT_START = 'selectstart';
const SELECT_CHANGE = 'selectionchange';
const CLICK = 'click';
const SCROLL = 'scroll';
const POINTER_DOWN = 'pointerdown';
const POINTER_UP = 'pointerup';
const INPUT = 'input';
const FOCUS = 'focus';
const BLUR = 'blur';
const DRAG = 'drag';
const DRAG_START = 'dragstart';
const DRAG_END = 'dragend';
const DRAG_ENTER = 'dragenter';
const DRAG_LEAVE = 'dragleave';
const DROP = 'drop';
const LONG_PRESS = 'longpress';
const MOUSE_OVER = 'mouseover';
const MOUSE_LEAVE = 'mouseleave';
const RESIZE = 'resize';

const CONTEXT_MENU = 'contextmenu';

export enum KeyboardCodeEnum {
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  SLASH = 'Slash',
  ESC = 'Escape',
  Backspace = 'Backspace',
  KeyC = 'KeyC',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Tab = 'Tab'
}

/**
 * 事件中心
 */
export class EventCenter {
  /**
   * 事件池
   */
  public subscribers: Map<string, any[]> = new Map();

  /**
   * 长按时间阈值,单位毫秒
   */
  public longPressTime: any = 500;

  /**
   * 定时器
   */
  public pressTimer: any;

  public startX: any;

  public startY: any;

  public static getPlatform(): string {
    return jsCookie.get(PLATFORM) || '';
  }

  public static isAndroid(): boolean {
    return this.getPlatform() === ANDROID;
  }

  public static isIos(): boolean {
    return this.getPlatform() === IOS;
  }

  public static isPC(): boolean {
    const platform = this.getPlatform();
    return platform !== undefined && platform !== ANDROID && platform !== IOS;
    // return false;
  }

  public static $EventDown(): string {
    return this.isPC() ? MOUSE_DOWN : TOUCH_START;
  }

  public static $EventUp(): string {
    return this.isPC() ? MOUSE_UP : TOUCH_END;
  }

  public static $EventMove(): string {
    return this.isPC() ? MOUSE_MOVE : TOUCH_MOVE;
  }

  public static $resize(): string {
    return RESIZE;
  }

  public static $SelectStart(): string {
    return SELECT_START;
  }

  public $SelectStart(): string {
    return SELECT_START;
  }

  public static $SelectChange(): string {
    return SELECT_CHANGE;
  }

  public $SelectChange(): string {
    return SELECT_CHANGE;
  }

  public static $Click(): string {
    return CLICK;
  }

  public $Click(): string {
    return CLICK;
  }

  public static $Scroll(): string {
    return SCROLL;
  }

  public $Scroll(): string {
    return SCROLL;
  }

  public static $PointerDown(): string {
    return POINTER_DOWN;
  }

  public $PointerDown(): string {
    return POINTER_DOWN;
  }


  public static $PointerUp(): string {
    return POINTER_UP;
  }

  public $PointerUp(): string {
    return POINTER_UP;
  }

  public static $MouseUp(): string {
    return MOUSE_UP;
  }

  public $MouseUp(): string {
    return MOUSE_UP;
  }


  public static $Input(): string {
    return INPUT;
  }

  public $Input(): string {
    return INPUT;
  }

  public static $Focus(): string {
    return FOCUS;
  }

  public $Focus(): string {
    return FOCUS;
  }

  public static $Blur(): string {
    return BLUR;
  }

  public $Blur(): string {
    return BLUR;
  }

  public static $ContextMenu(): string {
    return CONTEXT_MENU;
  }

  public $ContextMenu(): string {
    return CONTEXT_MENU;
  }

  public static $Drag(): string {
    return DRAG;
  }

  public $Drag(): string {
    return DRAG;
  }

  public static $DragEnter(): string {
    return DRAG_ENTER;
  }

  public $DragEnter(): string {
    return DRAG_ENTER;
  }

  public static $DragStart(): string {
    return DRAG_START;
  }

  public $DragStart(): string {
    return DRAG_START;
  }

  public static $DragEnd(): string {
    return DRAG_END;
  }

  public $DragEnd(): string {
    return DRAG_END;
  }

  public static $DragLeave(): string {
    return DRAG_LEAVE;
  }

  public $DragLeave(): string {
    return DRAG_LEAVE;
  }

  public static $Drop(): string {
    return DROP;
  }

  public $Drop(): string {
    return DROP;
  }

  public static $longPress(): string {
    return LONG_PRESS;
  }

  public $longPress(): string {
    return LONG_PRESS;
  }

  public static $MouseOver(): string {
    return MOUSE_OVER;
  }

  public $MouseOver(): string {
    return MOUSE_OVER;
  }

  public static $MouseLeave(): string {
    return MOUSE_LEAVE;
  }

  public $MouseLeave(): string {
    return MOUSE_LEAVE;
  }

  public getPlatform(): string {
    return jsCookie.get(PLATFORM) || '';
  }

  public isAndroid(): boolean {
    return this.getPlatform() === ANDROID;
  }

  public isIos(): boolean {
    return this.getPlatform() === IOS;
  }

  public isPC(): boolean {
    const platform = this.getPlatform();
    return platform !== undefined && platform !== ANDROID && platform !== IOS;
  }

  public $EventDown(): string {
    return this.isPC() ? MOUSE_DOWN : TOUCH_START;
  }

  public $EventUp(): string {
    return this.isPC() ? MOUSE_UP : TOUCH_END;
  }

  public $EventMove(): string {
    return this.isPC() ? MOUSE_MOVE : TOUCH_MOVE;
  }

  /**
   * 订阅事件的方法
   * @param event 
   * @param callback 
   * @param displayName 如果传递了这个 displayName 说明不能重复注册，只会注册一个事件 相同的就要删除
   */
  public subscribeEvent(event: any, callback: any, displayName?: string) {
    // 使用事件名查找对应的事件
    let find = this.subscribers.get(event);
    // 如果没有注册过那么就新建一个数组装装载该事件
    if (!find) {
      this.subscribers.set(event, []);
      find = this.subscribers.get(event);
    }
    // 组装事件成员
    const eventMember: any = {
      displayName: displayName || null,
      callback: callback
    };
    let subscribers_temp = [...find as any[]];
    if (displayName) {
      subscribers_temp = remove(find as any[], function (n: any) {
        return n.displayName && n.displayName !== displayName;
      });
    }
    subscribers_temp.push(eventMember);
    this.subscribers.set(event, subscribers_temp);
  }

  /**
   * 发布时间的方法
   * @param event
   * @param data
   * @param displayName string
   */
  public publishEvent(event: any, data?: any, displayName?: string) {
    let find = this.subscribers.get(event);
    if (displayName) {
      find = find?.filter(item => item.displayName === displayName);
    }
    if (find) {
      find.forEach((item: any) => {
        item?.callback(data);
      });
    }
  }

  /**
   * 取消订阅的分布
   * @param event 
   * @param callback 
   */
  public unsubscribeEvent(event: any, callback: any) {
    const find = this.subscribers.get(event);
    if (find) {
      this.subscribers.set(event, find.filter((item: any) => item.callback !== callback));
    }
  }

  /**
   * 在 pc 上注册一个长按事件
   */
  public registerLongPress() {
    if (this.isPC()) {
      document.addEventListener(this.$EventDown(), (event: any) => {
        const domNode = event.target;
        // 记录鼠标按下时间戳
        const startTime = new Date().getTime();
        // 启动定时器
        this.pressTimer = setTimeout(() => {
          // 触发长按事件
          const longPressEvent = new CustomEvent('longpress', {
            detail: { target: domNode }
          });
          document.dispatchEvent(longPressEvent);
        }, this.longPressTime);

        // 监听鼠标抬起事件
        document.addEventListener(this.$EventUp(), () => {
          clearTimeout(this.pressTimer); // 取消定时器
          const endTime = new Date().getTime();
          const pressDuration = endTime - startTime;
          // 如果按下时间小于长按时间阈值,则视为普通点击事件
          if (pressDuration < this.longPressTime) {
            console.log('普通点击事件');
          }
        }, { once: true });
      });
    }
  }
}
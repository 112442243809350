import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';

export interface IGetModelEssayRecordDto {
  id: number;
}

export interface ISaveModelEssayRecordDto {
  id: number;
  paper_id?: number;
  requirement?: string;
  thought?: string;
  subject?: string;
}

export const getModelEssayRecordApi = async (params: IGetModelEssayRecordDto): Promise<AxiosResponseType<any>> => {
  return await Axios.post('/api/exercise/get-big-essay-sample-text-info', params);
};

export const saveModelEssayRecordApi = async (params: ISaveModelEssayRecordDto): Promise<AxiosResponseType<any>> => {
  return await Axios.post('/api/exercise/save-big-essay-sample-text', params);
};

export const checkModelEssayApi = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/student/check-auth', params);
};

export const checkBigEssaySubjectApi = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exercise/check-big-essay-subject', params);
};

export const reBigEssaySubjectApi = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exercise/re-big-essay-sample-text', params);
};

export const checkBigEssayAuthApi = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/student/check-auth', params);
};
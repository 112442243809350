
import React, { ComponentType, ReactNode, Suspense, lazy, useImperativeHandle, useRef } from 'react';

export const lazyLoad = <T extends ComponentType<any>>(loader: () => Promise<T>) => {
  return lazy(() => loader().then((res) => {
    return { default: res };
  }));
};

export const withLazy = <T extends ComponentType<any>>(loader: () => Promise<T>, loading: ReactNode = null) => {
  const Comp = lazyLoad(loader);

  return (props: any) => {
    return <Suspense fallback={loading}>
      <Comp {...props} />
    </Suspense>;
  };
};

export const withLazyAndForwardRef = <T extends ComponentType<any>>(loader: () => Promise<T>, loading: ReactNode = null) => {
  const Comp = lazyLoad(loader);

  return React.forwardRef((props: any, ref) => {
    return <Suspense fallback={loading}>
      <Comp {...props} ref={ref} />
    </Suspense>;
  });
};


import React, { useEffect, useRef, useState } from 'react';
import { useMemoizedFn, useScroll } from 'ahooks';
import classNames from 'classnames';
import { Divider, Spin } from 'antd';
import { AxiosResponseType } from '@/utils/network/axios-helper';
import { SimpleSpin } from '@/components/Simple/SimpleSpin';
import { SearchItem } from '@/modules/List/SearchItem';
import store, { HistoryListSliceAction, IHistoryState, useAppSelector } from '@/stores';

import i18n from '@/locales/init';
import { useDocumentVisible } from '@/hooks/useDocumentVisible';
import lessModule from './history.module.less';

export type IItemType<Key extends keyof IHistoryState['listInfo']> = ArrayElementType<IHistoryState['listInfo'][Key]['list']>;

export interface IHistoryListHOCProps<Key extends keyof IHistoryState['listInfo']> {
  dataSetKey: Key;
  itemRenderer: React.ComponentType<{ item: IItemType<Key>; onHomePage: boolean;}>;
  fetchMethod: (props: { page: number; page_size: number; content?: string; }) => Promise<AxiosResponseType<{ list: Array<IItemType<Key>>; total: number; }>>;
  headerRenderer?: React.ComponentType<{}>;
  search?: boolean;
  onHomePage?: boolean;
}

export const historyListHOC: <Key extends keyof IHistoryState['listInfo']>(conf: IHistoryListHOCProps<Key>) => React.ComponentType<{}> = ({
  dataSetKey, itemRenderer: Item, fetchMethod, headerRenderer: Header, search, onHomePage
}) => {
  return React.memo(() => {
    const moduleDataSet = useAppSelector(state => state.historyList.listInfo[dataSetKey]);
    const reload = useAppSelector(state => state.historyList.reload);
    const list = moduleDataSet.list;
    const total = moduleDataSet.total;
    const page = moduleDataSet.currentPage;
    const pageSize = moduleDataSet.pageSize;
    const content = moduleDataSet.search_content;

    const [finished, setFinished] = useState<boolean>(false);

    const [fetching, setFetching] = useState<boolean>(false);

    const [fetched, setFetched] = useState<boolean>(false);

    const [more, setMore] = useState<number>(0);


    const listRef = useRef<HTMLDivElement | null>(null);

    const updateListInfo = useMemoizedFn((listInfo: Pick<IHistoryState['listInfo'][typeof dataSetKey], 'list' | 'total'>) => {
      store.dispatch(HistoryListSliceAction.setListInfo({ key: dataSetKey, value: listInfo as any }));
    });

    const shouldFetchMore = useMemoizedFn((): boolean => {
      if (onHomePage && page > 1){
        return false;
      }
      if (!listRef.current) {
        return false;
      }
      if (!listRef.current.children.length) {
        return true;
      }
      const container = listRef.current.getBoundingClientRect();
      const bottom = listRef.current.scrollHeight - listRef.current.scrollTop;
      // const bottom = listRef.current.children[listRef.current.children.length - 1].getBoundingClientRect().bottom;
      if (bottom < container.height + 200) {
        return true;
      }
      return false;
    });

    const fetchItem = useMemoizedFn(async () => {
      if (fetching) {
        return;
      }
      if (total > 0 && total <= list.length) {
        return;
      }
      if (finished) {
        return;
      }
      setFetching(true);

      try {
        const info = onHomePage ? {page: 1, page_size: 4} : { page, page_size: pageSize, content};
        const result = await fetchMethod(info);
        if (result.code === 200) {
          // result.data = {list: [], total: 0};
          if (result.data.list.length === 0 && page > 1) {
            // 为后端数据兜底，如果列表没有返回，认为当前列表已经没有数据了
            setFinished(true);
          } else {
            updateListInfo(result.data as any);
          }
        }
        setFetching(false);
        setFetched(true);
        setTimeout(() => {
          setMore(Math.random());
        }, 1000);
      } catch (e) {
        setFetching(false);
      }

    });

    useEffect(() => {
      if (shouldFetchMore() && list.length > 0 && list.length < total && more > 0) {
        fetchItem();
      }
    }, [more]);

    const scroll = useScroll(listRef);
    useEffect(() => {
      if (!listRef.current || !scroll) {
        return;
      }
      if (shouldFetchMore()) {
        fetchItem();
      }
    }, [scroll]);

    useEffect(() => {
      if (search) {
        setFetched(false);
        fetchItem();
      }
    }, [content, search]);

    useEffect(() => {
      if (reload) {
        setFetched(false);
        setFinished(false);
        fetchItem();
      }
    }, [reload]);

    useDocumentVisible(() => {
      store.dispatch(HistoryListSliceAction.reload({ key: dataSetKey }));
    });

    useEffect(()=>{
      return ()=>{
        store.dispatch(HistoryListSliceAction.clear({ key: dataSetKey }));
      };
    }, []);
    const nodata = (fetched && total <= 0);
    const endText = (total > 0 && list.length >= total) ? i18n.t('common.dauyan_writing_list_bottom') : (fetched && total <= 0) ? i18n.t('common.dauyan_no_data_temporarily') : '';
    return <div className={classNames(lessModule.wrapper, onHomePage && lessModule.onHomePage)}>
      {
        Header ? <div>
          <Header />
        </div> : null
      }
      <SimpleSpin spinning={fetching && list.length === 0}>
        <div className={classNames(lessModule.listWrapper, onHomePage ? '' : 'scroll-bar-visible', nodata && lessModule.listEmpty)} ref={listRef}>
          {
            list.map((ele) => {
              return <Item key={ele.id || (ele as any).record_id} item={ele as any} onHomePage={!!onHomePage}/>;
            })
          }
          {(!onHomePage || (fetched && total <= 0)) && <Divider plain>{endText}</Divider>}
        </div>
      </SimpleSpin>
      
      {search && <SearchItem className={lessModule.searchItem} content={content} onSearch={(content) => {
        setFinished(false);
        store.dispatch(HistoryListSliceAction.setSeachContent({ key: dataSetKey, content: content }));
      }}></SearchItem>}
    </div>;
  });
};

import React, { FC } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { LoadingOutlined } from '@ant-design/icons';
import css from './style.module.less';

interface SimpleSpinProps extends SpinProps {
}

export const SimpleSpin: FC<SimpleSpinProps> = (props) => {
  return <Spin {...props}
    className={props.className}
    indicator={props?.indicator ? props.indicator : <LoadingOutlined style={{ fontSize: 24 }} spin />} wrapperClassName={css.simpleSpin} />;
};